import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/blog/Header/BlogNavbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/global/404.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/global/Error.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/global/modules/footer/FooterWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/vercel/path0/components/global/modules/header/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live-stream.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/client-component.js");
